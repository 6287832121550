import { useAnimation, useInView, motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

const PopAnimation = ({children, width = 'fit-content'}) => {
const ref = useRef(null)
const isInView = useInView(ref, {once : true})
const popAnimation = useAnimation()

useEffect(()=>{
    if(isInView){
    popAnimation.start('visible')
    }
},[isInView])
  return (
    <div ref = {ref} style={{width : '100%'}}>
        <motion.div 
        variants={{
          hidden : {opacity : 0, y : 75},
          visible : {opacity : 1, y : 0}
        }}
        initial = "hidden"
        animate = {popAnimation}
        transition={{duration : 0.5, delay : 0.25 }}
        >
            {children}
        </motion.div>
    </div>
  )
}

export default PopAnimation