import React from 'react'
import Achievement from './Achievment'
import PopAnimation from '../../../PopAnimation'
import testImage from '../../../../logo.svg'
import './AchievmentsParent.css'


const achievement_data = [
  {
    image : testImage,
    discription : ' CVE-2024-25272, CVE-2024-25271',
    id : 1
  },
  {
    image : testImage,
    discription : 'CVE-2024-24143',
    id : 2
  },
  {
    image : testImage,
    discription : 'First place, Bosch global CTF 2022',
    id : 3
  },
  {
    image : testImage,
    discription : 'Bravo Award, Bosch 2022',
    id : 4
  },
  {
    image : testImage,
    discription : 'Best Tyro, Bosch 2021',
    id : 5
  }
]

const AchievmentsParent = () => {
  return (
    <div className='achievment_parent'>
      <PopAnimation>
        <div className='custom_title'>
          <h2 className='custom_text'>---- Achievments ----</h2>
        </div>
      </PopAnimation>
        {
          achievement_data.map((data) =>(
            <PopAnimation>
              <Achievement image_src={data.image} title={data.discription} toggle = {data.id}/>
            </PopAnimation>
          ))
        }
    </div>
  )
}

export default AchievmentsParent