import React from 'react';

function ContactPage() {
  return (
    <div className="page">
      <h2>Contact</h2>
      <p>Contact me at connect@aaravsinha.in.</p>
    </div>
  );
}

export default ContactPage;
