import React from 'react'
import './Achievment.css'

const Achievment = ({image_src, title, toggle}) => {
  return (
    <div className={toggle % 2 === 0 ? 'achievment_base_design individual_achievment_parent' : 'achievment_base_design individual_achievment_parent_reverse'}>
      <div>
        <img 
          alt = 'achievment_image'
          className='achievment_image'
          src={image_src}
        />
      </div>
      <div>
        <p className='achievment_title'>
          {title}
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nam id aperiam enim. Consequuntur exercitationem nostrum consectetur, non tempore inventore accusantium nihil. Tempora cum praesentium repellat. Obcaecati dolor consequatur voluptatibus!
        </p>
      </div>
    </div>
  )
}

export default Achievment