import React from 'react'
import './Card.css'

const Card = ({TitleText, Summary, Image}) => {
  return (
    <div className='Card-parent'>
        <img src={Image} className='Card-Image'></img>
        <p className='Card-Title'>{TitleText}</p>
        <p className='Card-Summary'>{Summary}</p>
    </div>
  )
}

export default Card