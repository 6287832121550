import React from 'react'
import Terminal from 'react-console-emulator'
import './Terminal.css'

const commands = {
  about: {
      description: 'About me',
      usage: 'echo <string>',
      fn: (...args) => {
        return (
          <>
            <h2>
                Hello! I'm a Application security researcher with a passion for keeping the digital word safe for use.
                I have a strong background in security fundamentals and have extensive experience with real life scenarios involving mobile, cloud and web applications.
            </h2>
          </>
        )
      }
    },

    skills:{
      description : 'Shows my skills',
      usage : 'skills',
      fn: (...args) =>{
        return (
          <>
            <ul>
                <li>OWASP Top 10 (Web and Mobile)</li>
                <li>Cloud Infrastructure Security</li>
                <li>Compliance Security</li>
                <li>Compliance security</li>
                <li>Coding and Scripting</li>
                <li>Wireless Security (WiFi and BLE)</li>
            </ul>
          </>
        )
      }
    },

    achievements:{
        description: 'Shows list of achievements',
        usage: 'Achievements',
        fn: (...args) => {
            return (
              <>
                <ul>
                    <li>CVE-2024-25272, CVE-2024-25271</li>
                    <li>CVE-2024-24143</li>
                    <li>First place, Bosch global CTF 2022</li>
                    <li>Bravo Award, Bosch 2022</li>
                    <li>Best Tyro, Bosch 2021</li>
                </ul>
              </>
            )
        }
    }

  }

const Terminalreact = () => {
  return (
    <div className='react_Terminal'>
      <Terminal
        commands={commands}
        welcomeMessage={'for more information type help in terminal'}
        promptLabel={'AARAV_SINHA:~$'}
      />
    </div> 
  )
}

export default Terminalreact