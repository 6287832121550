import React from 'react'
import Card from './Card'
import './Card.css'
import logo from '../../logo.svg'
import './CardDeck.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';


const CardDeck = () => {
  return (
    <>
        {/* <div className='custom_title'>
            <h2 className='custom_text'>---- Projects ----</h2>
        </div>
        <div className='CardDeck-parent'>
            <Card 
                TitleText={"AppDumper"} 
                Summary={"A JavaScript utility designed to extract memory blocks within specified start and end addresses from a system."} 
                Image={logo}
            />
            <Card 
                TitleText={"logShotter"} 
                Summary={"A command-to-control toolkit targeting Windows OS, facilitating keystroke logging and screen capture functionalities for potential malicious activities."} 
                Image={logo}
            />
            <Card 
                TitleText={"Task-hijacking-android"} 
                Summary={"A proof-of-concept attacker application aimed at demonstrating task hijacking capabilities on the Android platform."} 
                Image={logo}
            />
            <Card 
                TitleText={"Mobile-App-Pentesting"} 
                Summary={"A curated compilation of common OWASP vulnerabilities tailored for conducting penetration testing specifically on Android applications."} 
                Image={logo}
            />
        </div> */}
        <Swiper
            effect={'coverflow'}
            grabCursor = {true}
            centeredSlides = {true}
            loop = {true}
            slidesPerView={'auto'}
            coverflowEffect={{
                    rotate : 0,
                    stretch : 0,
                    depth : 100,
                    modifier : 2.5
                }
            }
            pagination = {{el : '', clickable : true}}
            modules={[EffectCoverflow, Pagination, Navigation]}
        >
            <SwiperSlide style={{width : '40%', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                <Card 
                    TitleText={"AppDumper"} 
                    Summary={"A JavaScript utility designed to extract memory blocks within specified start and end addresses from a system."} 
                    Image={logo}
                />
            </SwiperSlide>
            <SwiperSlide style={{width : '40%', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                <Card 
                    TitleText={"logShotter"} 
                    Summary={"A command-to-control toolkit targeting Windows OS, facilitating keystroke logging and screen capture functionalities for potential malicious activities."} 
                    Image={logo}
                />
            </SwiperSlide>
            <SwiperSlide style={{width : '40%', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                <Card 
                    TitleText={"Task-hijacking-android"} 
                    Summary={"A proof-of-concept attacker application aimed at demonstrating task hijacking capabilities on the Android platform."} 
                    Image={logo}
                />
            </SwiperSlide>
            <SwiperSlide style={{width : '40%', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                <Card 
                    TitleText={"Mobile-App-Pentesting"} 
                    Summary={"A curated compilation of common OWASP vulnerabilities tailored for conducting penetration testing specifically on Android applications."} 
                    Image={logo}
                />
            </SwiperSlide>
        </Swiper>
    </>
  )
}

export default CardDeck