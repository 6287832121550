import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import React from 'react'
import { color } from '../../../../Enum/Colors/Colors'
import './Timeline.css'
import LeftFadeInAnimation from '../../../LeftFadeInAnimation'
import FadeInAnimation from '../../../FadeInAnimation'

const TimelineComponent = ({placement = '', Time, Activity}) => {
  return (
    <div
      className='TimelineItem-parent'
    >
      <TimelineItem sx={{height : 200, justifyItems : 'center'}} >
        <LeftFadeInAnimation>
          <TimelineOppositeContent 
            color={color.white} 
            sx={{
              fontSize : 18,
              width : 300,
              fontWeight : 600,
              fontFamily: 'Courier New, monospace',
              px : 7,
              py : 2
            }}
          >
            {Time}
          </TimelineOppositeContent>
        </LeftFadeInAnimation>
        <TimelineSeparator>
          {/* <TimelineDot variant="outlined" color="success"/> */}
          <TimelineDot>
            <img src='hacker.png'></img>
          </TimelineDot>
          {
            !placement && <TimelineConnector/>
          }
        </TimelineSeparator>
        <FadeInAnimation>
          <TimelineContent 
            color={color.white} 
            sx={{
              fontSize : 18,
              width : 300,
              fontWeight : 600,
              fontFamily: 'Courier New, monospace',
              px : 7,
              py : 2
            }}
          >
            {Activity}
          </TimelineContent>
        </FadeInAnimation>
      </TimelineItem>
    </div>
  )
}

export default TimelineComponent