import React from 'react'
import "./PageHero.css"
import textImage from '../../../../logo.svg'

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ@#^&!"

const TextAnimation = (event) =>{
    let itrations = 0;
    const text = event.target.dataset.value
    const interval = setInterval(()=>{
        event.target.innerText = event.target.innerText.split("")
        .map((letter, index) => {
            if(index < itrations){
                return text[index]
            }
            return letters[Math.floor(Math.random() * letters.length)]
        })
        .join("")

        if(itrations >= text.length) clearInterval(interval)

        itrations += 1;
    },70)
}

const PageHero = () => {
  return ( 
    <div className='HeroParent'>
        <img className='PlaceHolder_Image' src= {textImage}></img>
        <div className='HeroChild-UserInfo'>
            <h1 
                data-value = "AARAV SINHA"
                className='HeadLine'
                onMouseOver={TextAnimation}    
            >AARAV SINHA</h1>
            <p>
                Hello! I'm a Application security researcher with a passion for keeping the digital word safe for use.
                I have a strong background in security fundamentals and have extensive experience with real life scenarios involving mobile, cloud and web applications.
            </p>
        </div>
    </div>
  )
}

export default PageHero