import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineComponent from './HomePageComponents/Timeline/TimelineComponent';
import PageHero from './HomePageComponents/PageHero/PageHero';
import PopAnimation from '../PopAnimation';
import CardDeck from '../Card/CardDeck';
import Terminalreact from './HomePageComponents/Terminal/Terminal';
import './HomePage.css'
import AchievmentsParent from './HomePageComponents/Achievements/AchievmentsParent';


const HomePage = () => {

  return (
    <div className='Home-parent'>

      <PopAnimation>
        <PageHero/>
      </PopAnimation>

      <PopAnimation>
        <Terminalreact/>
      </PopAnimation>

      <PopAnimation>
        <CardDeck/>
      </PopAnimation>

      <AchievmentsParent/>

      <PopAnimation>
        <Timeline position="alternate" sx={{justifyItems : 'center'}}>
            <TimelineComponent id = {1} Time={'2023-Present'} Activity={'Senior security researcher, FEV LTD.'}/>

            <TimelineComponent id = {2} Time={'2021-2023'} Activity={'Senior security engineer, Bosch Global Software Technologies'}/>

            <TimelineComponent id = {5} placement = "end" Time={'2021-2021'} Activity={'Digital Specialist engineer, Infosys LTD'}/>
        </Timeline>
      </PopAnimation>

    </div>
  )
}

export default HomePage