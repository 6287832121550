import React, { useEffect, useState } from 'react';
import Parser from 'rss-parser'
import axios from 'axios';
import '../Blog.css'; // Import the CSS file

function Blog() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    console.log("trying to fetch...");

    // const parser = new Parser()
    // const fetchPosts = async () => {
    //   const url = 'https://cors-anywhere.herokuapp.com/https://medium.com/feed/@aaravsinha'
    //   const feed = await parser.parseURL(url)
    //   setPosts(feed.items)
    // }
    // fetchPosts()
    const parser = new Parser();
    const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
  

    axios.get(`${CORS_PROXY}https://medium.com/feed/@aaravavi`, {
      headers: {
        Origin: 'https://www.aaravsinha.in',
        Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
      }
    })
      .then(response => {
        console.log(response.data);
        return parser.parseString(response.data);
      })
      .then(data => {
        setPosts(data.items); // Get the first 3 posts
      });
  }, []);

  return (
    <div className="blog">
      {posts.map(post => (
        <div key={post.guid} className="card">
          <h2>{post.title}</h2>
          <a href={post.link}>Read more</a>
        </div>
      ))}
    </div>
  );
}

export default Blog;
