import React from 'react';

function AboutPage() {
  return (
    <div className="page">
        <h2>Skills</h2>
        <ul>
            <li>Mobile Application Security</li>
            <li>Owasp top 10</li>
            <li>Cloud infrastruce securtity</li>
            <li>Compliance security</li>
            <li>Thick client security</li>
            <li>Coding and scripting</li>
        </ul>
    </div>
  );
}

export default AboutPage;
