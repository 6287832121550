import { useAnimation, useInView, motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

const LeftFadeInAnimation = ({children, width = 'fit-content'}) => {
    const ref = useRef(null)
    const isInView = useInView(ref, {once : true})
    const Fadein_animation = useAnimation()

    useEffect(()=>{
        if(isInView){
            Fadein_animation.start('visible')
        }
    },[isInView])
  return (
    <div>
        <div ref = {ref} style={{width : '100%'}}>
            <motion.div 
            variants={{
            hidden : {opacity : 0, x : -200},
            visible : {opacity : 1, x : 0}
            }}
            initial = "hidden"
            animate = {Fadein_animation}
            transition={{duration : 0.5, delay : 0.25 }}
            >
                {children}
            </motion.div>
        </div>
    </div>
  )
}

export default LeftFadeInAnimation